import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LogoBox } from '../Logo/Logo';
import { useNavigate } from 'react-router-dom';
import { DropMenuItem } from '../DropMenuItem/DropMenuItem'
import { DropMenuMobileVersion } from '../DropMenuMobileVersion/DropMenuMobileVersion';
import "./Header.scss"


const Header = ({ activeLang, setActiveLang, activeMenuItems, setActiveMenuItems }) => {
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
    const [widtWindow, setWidthWindow] = useState(null)
    const [isOpenInput, setIsOpenInput] = useState(false)
    const [isOpenMenu, setIsOpenMenu] = useState(null)
    const navigate = useNavigate()
    const path = useParams()

    const BASE_ROUTE = 'articles'


    useEffect(() => {
        window.onresize = () => {
            //  ReactDOM.render(<App />, document.getElementById('root'));
            setWidthWindow(window.innerWidth)
            setIsOpenMobileMenu(false)
        };
    });

    useEffect(() => {
        setWidthWindow(window.innerWidth)
    }, []);

    const menu_items = []


    const navMenuItems = menu_items.map(({ label, value, subItemsMenu, articles, path }, ind) =>
        <div className={activeMenuItems == label ? 'menu-items-wrap_active' : 'menu-items-wrap'} tabIndex='0' key={label + path + ind}
            onClick={(e) => setActiveMenuItems(label)}
            onMouseOver={(e) => setIsOpenMenu(label)}
            onMouseOut={(e) => setIsOpenMenu(null)}
        >
            <Link to={articles ? `main/${BASE_ROUTE}/${value ? value : label}` : path} key={label} className='header__nav-item'>{label}</Link>
            {subItemsMenu ? <DropMenuItem isOpenMenu={isOpenMenu} parentLabel={label} subItemsMenu={subItemsMenu} /> : null}
        </div>
    )

    useEffect(() => {
        setIsOpenMenu(null)
    }, [path]);


    return (
        <header className='header'>
            <div className='header__content'>
                <div className='nav-group'>
                    {(isOpenInput && widtWindow < 420) ? null : <LogoBox />}
                    <div className='btn-and-text'>
                        {(isOpenInput) ? null : <>
                            <a href='https://lk.gtcom.io/auth' target="_blank"><button className='btn-violet'>Войти</button></a>
                            <a href='https://lk.gtcom.io/auth/signup' target="_blank"><button className='btn-violet-border'>Зарегистрироваться</button></a>
                            <button className={isOpenMobileMenu ? 'btn-close' : 'btn-burger'} onClick={(e) => setIsOpenMobileMenu(!isOpenMobileMenu)}></button>
                        </>}

                    </div>
                </div>

                <nav className='header__nav'>
                    {/*  */}
                    {widtWindow < 420 ?
                        <DropMenuMobileVersion items={menu_items} isOpenMobileMenu={isOpenMobileMenu}  setOpen={(e) => setIsOpenMobileMenu(e)} />
                        : navMenuItems
                    }
                </nav>
            </div>
        </header >
    )
}

export { Header }