import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
    <I18nextProvider i18n={i18n} defaultNS={'common'}>
      <Routes>
        <Route path='/:lang/*' element={< App />} />
        <Route path='/*' element={< App />} />
      </Routes>
    </I18nextProvider>
    </BrowserRouter>
  </HelmetProvider>
);

