// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom'
import {  ReactComponent as Logo} from './img/Logo.svg'
import "./Logo.scss"

const LogoBox = () => {

    return (
        <Link className='logo' to='/articles/main' onClick={(e) => {
            window.scrollTo(0,0)
        }}>
            <Logo />
            {/* <Link><Logo /></Link> */}
       </Link>
    )
}

export { LogoBox }