import { Link } from 'react-router-dom'
import { FooterItem } from './FooterItem/FooterItem';
import { CopyrightBox } from './CopyrightBox/CopyrightBox';
import { SocialMediaAddress } from '@lk-gtcom/ecomlab-components';
import './Footer.scss'

const footerItems = [
    {
        label: 'СЕРВИСЫ',
        subItems: [
            {
                label: 'Аналитика',
            },
            {
                label: 'Мониторинг конкурентов',
            },
            {
                label: 'Управление заказами',
            },
            {
                label: 'Управление остатками',
            },
            {
                label: 'Интеграции',
            },
        ],
    },
    {
        label: '',
        subItems: [
            {
                label: 'Маркетинг',
            },
            {
                label: 'Рекламные кампании',
            },
            {
                label: 'Ключевые слова',
            },
            {
                label: 'Обогащение карточек',
            },
            {
                label: 'Авто биддер',
            },
        ],
    },
    {
        label: 'ПАРТНЕРАМ',
        subItems: [
            {
                label: 'Реферальная программа',
            },
            {
                label: 'Рекламным агентствам',
            },
            {
                label: 'Банкам',
            },
            {
                label: 'Логистическим компаниям',
            },
            {
                label: 'Бизнес тренерам',
            },
        ],
    },
    {
        label: 'О компании',
        subItems: [
            {
                label: 'Миссия',
                path: 'https://new.ecomru.ru/articles/about/Missija_kompanii_ECOMSELLER/1156',
                target: true
            },
            {
                label: 'О компании',
                path: 'https://new.ecomru.ru/articles/about/O_kompanii/395',
                target: true
            },
            {
                label: 'Офисы',
            },
            {
                label: 'Новости',
                path: 'https://new.ecomru.ru/articles/news',
                target: true
            },
        ],
    },
    {
        label: 'ОБУЧЕНИЕ',
        subItems: [
            {
                label: 'Кейсы',
                path: 'https://new.ecomru.ru/articles/cases',
                target: true
            },
            {
                label: 'Блог',
                path: 'https://new.ecomru.ru/articles/news'
            },
            {
                label: 'Видео',
            },
        ],
    },
];

const Footer = ({ activeLang, portal_id}) => {
    return (
        <footer className="footer">
            <div className='footer__content'>
                <div className="footer__upper-box">
                    <nav className="footer__nav">
                        {footerItems.map(({ label, subItems }) => (
                            <FooterItem
                                key={label}
                                label={label}
                                subItems={subItems}
                            />
                        ))}
                        <div className="footer__item">
                            <h4 className="footer__title">ECOMLAB</h4>
                            <address className="footer__address">
                                <ul className="footer__list">
                                    <li className="footer__list_item">
                                        <Link to="tel:8(800)301-65-63" className="address__link address__link_tel">8(800)301-65-63</Link>
                                    </li>
                                    <li className="footer__list_item">
                                        <Link to="mailto:info@ecomru.ru" className="address__link address__link_mail">info@ecomru.ru</Link>
                                    </li>
                                    <li className="footer__list_item">
                                        <Link to="#" className="address__link address__link_send">Поддержка</Link>
                                    </li>
                                </ul>
                            </address>
                        </div>
                    </nav>

                    <SocialMediaAddress
                        activeLang={activeLang}
                        portal_id={portal_id}
                    />
                </div>
                <div className='footer__bottom-box'>
                    <CopyrightBox/>
                </div>
            </div>
        </footer>
    )
}

export { Footer }