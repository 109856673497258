import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './DropMenuItem.scss';
import cases from './img/cases.svg'
import examples from './img//examples.svg'
import our_projects from './img/our_projects.svg'

function DropMenuItem({ parentLabel, subItemsMenu, isOpenMenu }) {

    const BASE_ROUTE = 'articles'

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate]);

    console.log(isOpenMenu)

    return (
        <div className='drop-list-menu'  style={{display: isOpenMenu == parentLabel ? 'flex' : ''}}>
            <div className='drop-list-menu__content' style={{ width: parentLabel == "Отрасли" ? '50%' : '70%' }}>
                {parentLabel == "Solutions" && <div className='section-item'>
                    <p className='text_grey-underline'>Solutions</p>
                </div>}
                {parentLabel == "Cases" && <div className='section-item'>
                    <img src={our_projects}/>
                    <p className='text_grey-underline'>Our Projects</p>
                </div>}
                {parentLabel == "Кейсы" && <div className='section-item'>
                    <div className='img-box'>
                        <img src={examples} />
                    </div>
                    <p className='text_grey-underline'>Примеры наших проектов</p>
                </div>}
                <ul className='list-menu'>
                    {subItemsMenu.map(({ label_item, description, icon, path }, ind) =>
                        label_item && <li className='list-menu__item' key={label_item + ind}>
                            <Link to={`/main/${BASE_ROUTE}/${parentLabel}/${label_item}`} className='link_white'>
                                {icon && <div className='img-box'>
                                    <img src={icon} />
                                </div>}
                                <div className='name-box'>
                                    <h5 className='title'>{label_item}</h5>
                                    {description && <p className='text_grey'>{description}</p>}
                                </div>
                            </Link>
                        </li>)}
                </ul>
            </div>

            {parentLabel == "Products" &&
                <div className='current-section_case1'>
                    <div className='current-section_case1__content'>
                        {subItemsMenu.map(({ label_main_1, description_main_1, icon_main_1, img_main_1 }, ind) =>
                            label_main_1 && <>
                                <div className='link_white' key={label_main_1 + ind}>
                                    <div className='img-box'>
                                        <img src={icon_main_1} />
                                    </div>
                                    <div className='name-box'>
                                        <h5 className='title'>{label_main_1}</h5>
                                        <p className='text_grey'>{description_main_1}</p>
                                    </div>
                                </div>
                                <div className='img-container'>
                                    <img src={img_main_1} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            }

            {parentLabel == "Solutions" &&

                <div className='current-section_case2'>

                    <div className='section-item'>
                        <p className='text_grey-underline'>Case</p>
                    </div>

                    <div className='content-group'>
                        {subItemsMenu.map(({ label_main_2, description_main_2, icon_main_2, img_main_2 }, ind) =>
                            label_main_2 &&
                            <div key={label_main_2 + ind} className='current-section_case2__content'>
                                <div className='content-group__item'>
                                    <div className='link_white'>
                                        <div className='img-box'>
                                            <img src={icon_main_2} />
                                        </div>
                                        <div className='name-box'>
                                            <h5 className='title'>{label_main_2}</h5>
                                            <p className='text_violet'>{description_main_2}</p>
                                        </div>
                                    </div>
                                    <div className='img-group'>
                                        <div className='img-container'>
                                            <img src={img_main_2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            }

            {parentLabel == "Cases" &&
                <div className='current-section_case3'>
                    <div className='section-item'>
                        <div className='img-box'>
                            <img src={cases} />
                        </div>
                        <p className='text_grey-underline'>Case</p>
                    </div>
                    {subItemsMenu.map(({ label_main_3, description_main_3, img_main_3 }, ind) =>
                        label_main_3 && <div key={label_main_3 + ind} className='current-section_case3__content'>
                            <div className='link_white'>
                                <div className='name-box'>
                                    <h5 className='title'>{label_main_3}</h5>
                                    <p className='text_grey'>{description_main_3}</p>
                                </div>
                            </div>
                            <div className='img-container'>
                                <img src={img_main_3} />
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export { DropMenuItem };