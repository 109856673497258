import Footer from './components/Footer'
import { Header } from './components/Header/Header'
import { useState, useEffect, createContext } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { ArticleMainContainer } from '@lk-gtcom/ecomlab-components'
import { Page404 } from './pages/Page404/Page404'
import { Helmet } from 'react-helmet-async';
import './index.scss'

export const LANGS = {
  // TUR: 'tur',
  // ENG: 'eng',
  RUS: 'ru'
}

export const BASE_ROUTE = 'articles'
export const PORTAL_ID = 3


function App() {
  const [activeLang, setActiveLang] = useState(() => localStorage.getItem('lang') ?? LANGS.ENG)

  const setLanguage = lang => {
    let langForHtml
    switch (lang) {
      case LANGS.ENG:
        langForHtml = 'en'
        break;
      case LANGS.TUR:
        langForHtml = 'tr'
        break;
      case LANGS.RUS:
        langForHtml = 'ru'
        break;
      default:
        throw new Error('unknown language')
    }
    document.documentElement.setAttribute('lang', langForHtml)
    localStorage.setItem('lang', lang)
    setActiveLang(lang)
  }

  const path = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('portal_id', 3)
    let {lang} = path
    if(!activeLang && lang){
      setLanguage(LANGS?.[lang?.toUpperCase()])
    } else {
      lang = 'ru'
      setLanguage(LANGS?.RUS)
    }



    if (path['*'] === '') {
      navigate(`/${lang}/articles/start`)
    }

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate]);

  let pathBread = path['*'].split('/')[0]

  return (
    <>
      <main className='main'>
        <Helmet>
          <script type="application/ld+json">
              {
                `{
                      "@context":"https://schema.org/",
                      "@type":"Organization",
                      "name": "Wiki Ecomru",
                      "telephone":"+78003016563",
                      "email":"info@ecomru.ru",
                      "address":{
                          "@type":"PostalAddress",
                          "postalCode":"117342",
                          "addressCountry":"Россия",
                          "addressLocality":"Москва",
                          "streetAddress":"ул. Бутлерова, д. 17Б"
                      }
                }`
              }
          </script>
        </Helmet>
        <div className='content'>
          <Header activeLang={activeLang} setActiveLang={(e) => setActiveLang(e)} />
          <Routes>
          <Route path='/*' element={
            <ArticleMainContainer
              LANGS={LANGS}
              BASE_ROUTE={BASE_ROUTE}
              PORTAL_ID={PORTAL_ID}
              activeLang={activeLang}
              pathBread={pathBread} />} 
            />

            <Route path='/*' element={<Page404 activeLang={activeLang}/>} />
          </Routes>
        </div>
      </main>
      <Footer activeLang={activeLang} portal_id={PORTAL_ID} />
    </>
  )
}
export const NotificationContext = createContext(false)

export default App
